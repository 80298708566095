import { configureStore, combineReducers } from "@reduxjs/toolkit";
import events from "./slices/events";
import batch from "./slices/batch";

const rootReducer = combineReducers({
    events,
    batch
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware()
});

import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
// import Game from '../ethers/abis/Game.json';
import Insurance from '../ethers/abis/Insurance.json';

const InsuranceInterface = new Interface(
    Insurance
);

export const usePotentialHero = (
    insuranceAddress,
    playerAddress
) => {
    const haveParams = insuranceAddress && playerAddress;
    const potentialHero = useContractCall(haveParams &&
        {
            abi: InsuranceInterface,
            address: insuranceAddress,
            method: "potentialRegisterIDsUserHero",
            args: [playerAddress],
        }
    ) ?? [];

    return potentialHero.toString();
}

export default usePotentialHero;

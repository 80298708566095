import {useSelector} from "react-redux";
import cn from "classnames";
import { Button } from "nes-react";

import config from "../config";
import useHeroStats from "../hooks/useHeroStats";

import {
    canReveal, alreadyReveal, awaitingReveal,
    heroButtonStyle,
    heroButtonText,
    isHeroGenesis
} from "./helpers";

import {
    selectEvents,
    selectBatch
} from "../state";

import { HERO } from "../config";

export const HeroData = ({
    heroID,
    openModalHero,
    purchaseHero,
    isIdleToken = false,
}) => {

    const events = useSelector(selectEvents);
    const batch = useSelector(selectBatch);
    const { purchaseEvents, claimingEvents } = events;
    const { currentBatch } = batch;

    const heroStats = useHeroStats(
        heroID,
        config.insuranceAddress
    );

    const heroLevel = heroStats.level
    const heroMultiplier = heroStats.heroMultiplier;

    const isGenesis = isHeroGenesis(
        heroID
    );

    const handleClick = () => {
        if (isIdleToken) {
            return;
        }
        openModalHero(
            heroID.toString(),
            heroLevel.toNumber()
        );
    }

    return (
        <>
            {heroID && (
                <div
                    className={!isIdleToken ? "single-item" : ""}
                    style={{ marginTop: "50px" }}
                    onClick={handleClick}
                >
                    <div>Hero: {heroID.toString()}</div>
                    {heroLevel && heroMultiplier && (
                        <div>
                            <div className={"level-bubble-left"}>
                                LVL: {heroLevel.toString()}
                            </div>
                            <div className={"level-bubble-right"}>
                                MUL: {heroMultiplier.toString()}
                            </div>
                        </div>
                    )}
                    <div
                        className={cn("img-holder-hero", { genesis: isGenesis })}
                    >
                        <img
                            alt={``}
                            className={cn("hero-image", { genesis: isGenesis })}
                            style={{ marginTop: "0px" }}
                            src={`https://cdn.raid.party/hero/transparent/${heroID.toString()}.png`}
                        />
                    </div>
                    {isIdleToken === false ? (
                        <>
                            {canReveal(
                                heroID.toString(),
                                currentBatch,
                                HERO,
                                purchaseEvents
                            ) === true ? (
                                <div>
                                    {alreadyReveal(
                                        heroID.toString(),
                                        currentBatch,
                                        HERO,
                                        claimingEvents
                                    ) === false ? (
                                        <Button success>Safe Reveal</Button>
                                    ) : (
                                        <Button error>Completed</Button>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {awaitingReveal(
                                        heroID.toString(),
                                        currentBatch,
                                        HERO,
                                        purchaseEvents
                                    ) === true ? (
                                        <Button warning>Await Seed</Button>
                                    ) : (
                                        <Button
                                            primary={heroButtonStyle(purchaseHero,
                                                heroID.toString(),
                                                heroLevel && heroLevel.toNumber()
                                            )}
                                        >
                                            {heroButtonText(
                                                purchaseHero,
                                                heroID.toString(),
                                                heroLevel && heroLevel.toNumber()
                                            )}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <div>
                            Equip First
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
import Insurance from '../ethers/abis/Insurance.json';

import {
    FIGHTER,
} from "../config";

const InsuranceInterface = new Interface(
    Insurance
);

export const useRegisterToken = (
    insuranceAddress,
    tokenID,
    tokenType
) => {
    const haveParams = insuranceAddress && tokenID;

    const methodName = tokenType === FIGHTER
        ? "batchNumberRegisterFighter"
        : "batchNumberRegisterHero";

    const [batchNumber] = useContractCall(haveParams &&
        {
            abi: InsuranceInterface,
            address: insuranceAddress,
            method: methodName,
            args: [tokenID],
        }
    ) ?? [];

    return batchNumber;
}

export default useRegisterToken;

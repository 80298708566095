import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    purchaseEvents: [],
    claimingEvents: []
};

export const eventSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        setPurchaseEvents(state, action) {
            state.purchaseEvents = action.payload;
        },
        setClaimingEvents(state, action) {
            state.claimingEvents = action.payload;
        }
    }
});

export const {
    setPurchaseEvents,
    setClaimingEvents
} = eventSlice.actions;

export default eventSlice.reducer;

import { useState, useEffect } from "react";
import axios from "axios";
import { useEthers } from "@usedapp/core";
import { Interface } from "ethers/lib/utils";

import { getTokenCollections, getMainNetAddress } from "../ethers/nftCollections";
import { BigNumber, Contract } from "ethers";

const getMetaData = async (collections, chainId, userAccount) => {
    const results = await Promise.all(
        Object.keys(collections).map(async (address) => {

            const collection = collections[address];
            const size = 30;
            const chunks = [];

            while (collection.length) {
                chunks.push(collection.splice(0, size));
            }

            const responseChunks = await Promise.all(
                chunks.map(async (chunk) => {

                    const tokenIds = chunk.map((id) => `token_ids=${id.toNumber()}`).join("&");

                    const contractAddress = chainId === 3
                        ? getMainNetAddress(address)
                        : address;

                    // @TODO: API in config https://api.opensea.io
                    const opensea = `https://api.opensea.io/api/v1/assets?${tokenIds}&asset_contract_address=${contractAddress}&order_direction=desc`;

                    const res = await axios.get(opensea, {
                        crossdomain: true
                    });

                    const { assets } = res.data;

                    const dataChunk = assets.map((a) => ({
                        address,
                        id: BigNumber.from(a.token_id),
                        data: { image: a.image_url },
                        owner: userAccount,
                    }));

                    return dataChunk;
                })
            );
            const flattened = responseChunks.flat();
            return flattened;
        })
    );
    return results;
};

const parseLog = (log, contractInterface) => ({
    blockNumber: log.blockNumber,
    ...contractInterface.parseLog(log),
});

/*const enumerableInterface = new Interface([
    "function balanceOf(address owner) returns (uint256)",
    "function tokenOfOwnerByIndex(address owner, uint256 index) returns (uint256)",
    "function tokenURI(uint256 tokenId) returns (string)",
]);*/

const indexedTransferInterface = new Interface([
    "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
    "function tokenURI(uint256 tokenId) returns (string)",
]);

/*const punkInterface = new Interface([
    "event Assign(address indexed to, uint256 punkIndex)",
    "event PunkBought(uint indexed punkIndex, uint value, address indexed fromAddress, address indexed toAddress)",
    "event PunkTransfer(address indexed from, address indexed to, uint256 punkIndex)",
]);*/

const useTokenFetch = (optionalAddress) => {

    const { chainId, account, library } = useEthers();
    const [allTokens, setAlltokens] = useState([]);
    const [indexedTokenObjects, setIndexedTokenObjects] = useState([]);

    const userAccount = optionalAddress
        ? optionalAddress
        : account;

    /* punk whale address for testing mainnet: */
    // const userAccount = "0xc352b534e8b987e036a93539fd6897f53488e56a";

    const {
        // enumerable,
        indexedTransfer,
        peculiar
    } = getTokenCollections(chainId);

    // const enumAddresses = enumerable.map((e) => e.address);
    const indexedAddresses = indexedTransfer.map((i) => i.address);

    /*const enumerableTokenBalanceCalls = chainId && userAccount ?
        enumerable.map((contract) => {
                return {
                    abi: enumerableInterface,
                    address: contract.address,
                    method: "balanceOf",
                    args: [userAccount],
                };
            }
        ) : [];*/

    // const enumerableTokenBalances = useContractCalls(
        // enumerableTokenBalanceCalls
    // );

    /*const enumerableTokenIdCalls = enumerableTokenBalances.reduce((prev, curr, index) => {
        if (!curr || !curr[0]) return prev;
        const current = [];
        for (let i = 0; i < curr[0].toNumber(); i++) {
            current.push({
                abi: enumerableInterface,
                address: enumerable[index].address,
                method: "tokenOfOwnerByIndex",
                args: [userAccount, i],
            });
        }
        return [...prev, ...current];
    }, []);*/

    // const enumerableTokenIds = useContractCalls(
        // enumerableTokenIdCalls || []
    // );

    /*
    const enumerableCollections = enumerableTokenIds.reduce((prev, idResponse, index) => {
        if (!idResponse || !idResponse[0]) return prev;
        const id = idResponse[0];
        const address = enumerableTokenIdCalls[index].address;
        if (prev[address]) {
            return {
                ...prev,
                [address]: [...prev[address], id],
            };
        } else {
            return {
                ...prev,
                [address]: [id]
            };
        }
    }, {});

    const enumerableStringified = JSON.stringify(
        enumerableCollections
    );*/

    /* useEffect(() => {
        let mounted = true;
        // setAlltokens((tokens) => tokens.filter((t) => !enumAddresses.includes(t.address)));

        const fetch = async () => {

            const results = await getMetaData(
                enumerableCollections,
                chainId,
                userAccount
            );

            // console.log(results, 'results');

            if (mounted) setAlltokens((t) => [
                ...t,
                ...results.flat()
            ]);
        };

        fetch();
        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enumerableStringified]);*/

    const indexedCollections = indexedTokenObjects.reduce((prev, obj) => {
        const { id, address } = obj;
        if (prev[address]) {
            return {
                ...prev,
                [address]: [...prev[address], id]
            };
        } else {
            return {
                ...prev,
                [address]: [id]
            };
        }
    }, {});

    const indexedStringified = JSON.stringify(
        indexedCollections
    );

    useEffect(() => {
        let mounted = true;

        setAlltokens((tokens) => tokens.filter(
            (t) => !indexedAddresses.includes(
                t.address
            )
        ));

        const fetch = async () => {

            const results = await getMetaData(
                indexedCollections,
                chainId,
                userAccount
            );

            if (mounted) setAlltokens((t) => [
                ...t,
                ...results.flat()
            ]);
        };

        fetch();
        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexedStringified]);

    /*const parsePunkLog = (log) => parseLog(
        log,
        punkInterface
    );*/

    const getMostRecentEvents = (events, field) => {
        return events.reduce((prev, curr) => {
            const index = curr.args[field];
            const entryAtIndex = prev[index];
            if (!entryAtIndex || curr.blockNumber > entryAtIndex.blockNumber) {
                return { ...prev, [index]: curr };
            }
            return prev;
        }, {});
    };

    /*const getMostRecentPunkEvents = (events) => getMostRecentEvents(
        events,
        "punkIndex"
    );*/

    const getMostRecentTokenEvents = (events) => getMostRecentEvents(
        events,
        "tokenId"
    );

    const getFilteredLogs = (filter) => library.getLogs({
        ...filter,
        fromBlock: 0 // @TODO: put inception block
    });

    const punkContractAddress = peculiar[0].address;
    // const punkContract = new Contract(punkContractAddress, punkInterface);
    // const punkAssignEventFilter = punkContract.filters.Assign(userAccount);
    // const punkTransferToEventFilter = punkContract.filters.PunkTransfer(null, userAccount);
    // const punkTransferFromEventFilter = punkContract.filters.PunkTransfer(userAccount);
    // const punkBoughtFromEventFilter = punkContract.filters.PunkBought(null, null, userAccount);
    // const punkBoughtToEventFilter = punkContract.filters.PunkBought(null, null, null, userAccount);

    useEffect(() => {
        let mounted = true;
        setAlltokens((tokens) => tokens.filter((t) => t.address !== punkContractAddress));
        /*const getPunkEvents = async () => {
            const punkAssignLogs = await getFilteredLogs(punkAssignEventFilter);
            const punkTransferToLogs = await getFilteredLogs(punkTransferToEventFilter);
            const punkTransferFromLogs = await getFilteredLogs(punkTransferFromEventFilter);
            const punkBoughtFromLogs = await getFilteredLogs(punkBoughtFromEventFilter);
            const punkBoughtToLogs = await getFilteredLogs(punkBoughtToEventFilter);

            const punkAssignEvents = punkAssignLogs.map(parsePunkLog);
            const punkTransferToEvents = punkTransferToLogs.map(parsePunkLog);
            const punkTransferFromEvents = punkTransferFromLogs.map(parsePunkLog);
            const punkBoughtFromEvents = punkBoughtFromLogs.map(parsePunkLog);
            const punkBoughtToEvents = punkBoughtToLogs.map(parsePunkLog);

            const mostRecentPunkTransferTo = getMostRecentPunkEvents(punkTransferToEvents);
            const mostRecentPunkTransferFrom = getMostRecentPunkEvents(punkTransferFromEvents);
            const mostRecentPunkBoughtFrom = getMostRecentPunkEvents(punkBoughtFromEvents);
            const mostRecentPunkBoughtTo = getMostRecentPunkEvents(punkBoughtToEvents);

            const currentlyOwnedPunkEvents = punkAssignEvents.reduce((prev, curr) => {
                const { punkIndex } = curr.args;
                const correspondingPunkBoughtFrom = mostRecentPunkBoughtFrom[punkIndex];
                const correspondingPunkTransferFrom = mostRecentPunkTransferFrom[punkIndex];
                if (!correspondingPunkBoughtFrom && !correspondingPunkTransferFrom) {
                    return [...prev, curr];
                }
                return prev;
            }, []);

            const addToOwnedListIfNotTransferredOut = (events) => {
                Object.keys(events).forEach((punkIndex) => {
                    const evt = events[punkIndex];
                    const correspondingPunkBoughtFrom = mostRecentPunkBoughtFrom[punkIndex];
                    const correspondingPunkTransferFrom = mostRecentPunkTransferFrom[punkIndex];
                    if (
                        (!correspondingPunkBoughtFrom ||
                            evt.blockNumber > correspondingPunkBoughtFrom?.blockNumber) &&
                        (!correspondingPunkTransferFrom ||
                            evt.blockNumber > correspondingPunkTransferFrom?.blockNumber)
                    ) {
                        currentlyOwnedPunkEvents.push(evt);
                    }
                });
            };

            addToOwnedListIfNotTransferredOut(
                mostRecentPunkTransferTo
            );

            addToOwnedListIfNotTransferredOut(
                mostRecentPunkBoughtTo
            );

            const reducedPunkEvents = currentlyOwnedPunkEvents.reduce(
                (prev, e) => ({
                    ...prev,
                    [e.args.punkIndex]: {
                        uri: null, // why?
                        owner: userAccount,
                        data: {
                            image: `https://www.larvalabs.com/public/images/cryptopunks/punk${e.args.punkIndex
                                .toString()
                                .padStart(4, "0")}.png`,
                            punk: true,
                            punkIndex: e.args.punkIndex,
                        },
                        address: punkContractAddress,
                    },
                }),
                {}
            );
            const ownedPunks = Object.values(
                reducedPunkEvents
            );

            if (mounted) {
                setAlltokens((t) => [...t, ...ownedPunks]);
            }
        };
        */

        const parseIndexedLog = (log) => parseLog(
            log,
            indexedTransferInterface
        );

        const getIndexedEventTokens = async () => {
            const indexedTokenCollections = await Promise.all(
                indexedTransfer.map(async (contract) => {
                    try {
                        const indexedTransferContract = new Contract(
                            contract.address,
                            indexedTransferInterface
                        );

                        const indexedFromFilter = indexedTransferContract.filters.Transfer(userAccount);
                        const indexedFromLogs = await getFilteredLogs(indexedFromFilter);
                        const indexedFromEvents = indexedFromLogs.map(parseIndexedLog);
                        const indexedToFilter = indexedTransferContract.filters.Transfer(
                            null,
                            userAccount
                        );
                        const indexedToLogs = await getFilteredLogs(indexedToFilter);
                        const indexedToEvents = indexedToLogs.map(parseIndexedLog);
                        const mostRecentTransfersTo = getMostRecentTokenEvents(indexedToEvents);
                        const mostRecentTransfersFrom = getMostRecentTokenEvents(indexedFromEvents);

                        const stillOwnedIndexedTokens = Object.keys(mostRecentTransfersTo).reduce(
                            (prev, key) => {
                                const current = mostRecentTransfersTo[key];
                                const correspondingFrom = mostRecentTransfersFrom[key];
                                if (
                                    !correspondingFrom ||
                                    current.blockNumber > correspondingFrom.blockNumber
                                ) {
                                    return [...prev, current];
                                }
                                return prev;
                            },
                            []
                        );
                        return {
                            tokens: stillOwnedIndexedTokens,
                            address: contract.address,
                        };
                    } catch (err) {
                        // console.log("getIndexedEventTokens error! contract: ", contract);
                    }
                })
            );
            const indexedTokens = indexedTokenCollections.reduce((prev, curr) => {
                const tokensOfCollection = curr.tokens.map((tokenEvent) => ({
                    id: tokenEvent.args.tokenId,
                    address: curr.address,
                }));
                return [...prev, ...tokensOfCollection];
            }, []);

            if (mounted) setIndexedTokenObjects(indexedTokens);
        };

        if (library && userAccount && chainId) {
            // getPunkEvents();
            getIndexedEventTokens();
        }

        return () => (mounted = false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, userAccount, library]);

    return allTokens;
};

export default useTokenFetch;

import { useContractCall } from "@usedapp/core";
import { Interface } from "ethers/lib/utils";
import Insurance from '../ethers/abis/Insurance.json';

const InsuranceInterface = new Interface(
    Insurance
);

export const useHeroStats = (userHero, insuranceAddress) => {
    const haveParamsStats = insuranceAddress && userHero;

    const [multiplier, size, level] = useContractCall(haveParamsStats && {
        abi: InsuranceInterface,
        address: insuranceAddress,
        method: "getStatsHero",
        args: [userHero],
    }
    ) ?? [];

    return {
        tokenID: userHero,
        level,
        size,
        heroMultiplier: multiplier,
    };
}

export default useHeroStats;

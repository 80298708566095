import { ethers } from "ethers";

export const gameAddress = "0xd311bDACB151b72BddFEE9cBdC414Af22a5E38dc";
export const heroAddress = "0x966731dFD9b9925DD105FF465687F5aA8f54Ee9f";
export const fighterAddress = "0x87E738a3d5E5345d6212D8982205A564289e6324";
export const confettiAddress = "0xCfef8857E9C80e3440A823971420F7Fa5F62f020";
export const insuranceAddress = "0xe61ab5b5348ee383561676D8b76353a9234d0179";
export const insuranceAddressView = "0xefF20e35d9a1E6b3C1d8f4355fc0Aec6b29E3A4F";
export const inceptionBlock = 14765138;

export const COST_WITH_HERO = 4;
export const MAX_HERO_LEVEL = 8;
export const MAX_FIGHTER_LEVEL = 8;
export const GENESIS_LIMIT = 1112;
export const FIGHTER = 'Fighter';
export const HERO = 'Hero';

export const OPENSEA_DEFAULT_TOKEN_IMG = 'https://lh3.googleusercontent.com/OyYvMx1DF1-J8nBin7dNQA8I1VKfKmZ7S-zl7D1sjFiB6xBQvy_ZP3lZ0OeduINPc_wpmyiiUcTl4YDRqAnq0vVxVzW5mdfqqwhOxA';

export const APPROVE_MAX_VALUE = ethers.constants.MaxUint256;

const config = {
    gameAddress,
    heroAddress,
    fighterAddress,
    confettiAddress,
    insuranceAddress,
    inceptionBlock,
    insuranceAddressView
}

export default config;

import React, { useEffect, useState } from "react";

import { formatEther } from "@ethersproject/units";
import ethLogo from "../images/eth-pixel.png";
import cftiLogo from "../images/cfti-pixel.png";
import perseIcon from "../images/perse.png";
import rulesIcon from "../images/contract.png";
import Modal from 'react-modal';

import {
    customStyles
} from './styles';

import { Button } from "nes-react";

import {
    Image,
    // Link
} from "../components";

import config, { FIGHTER, HERO } from "../config";

import {
    useEthers,
    shortenAddress,
    useEtherBalance,
    useTokenBalance,
    useLookupAddress,
} from "@usedapp/core";

import {
    tableHeros,
    tableFighters,
} from "./tables.js"

export const WalletHeader = () => {

    const [rendered, setRendered] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [ruleMode, setRuleMode] = useState(FIGHTER);

    const ens = useLookupAddress();

    const closeModal = () => {
        setIsOpen(false);
        // setSelectedToken();
    }

    const switchDetails = () => {
        
        ruleMode === HERO
            ? setRuleMode(FIGHTER)
            : setRuleMode(HERO);
    }

    const openModal = (
        tokenID,
        tokenIndex
    ) => {
        setIsOpen(true);
    }

    const {
        account,
        activateBrowserWallet,
        // deactivate,
        error
    } = useEthers();

    const confettiBalance = useTokenBalance(
        config.confettiAddress,
        account
    );

    useEffect(() => {
        if (ens) {
            setRendered(ens);
        } else if (account) {
            setRendered(
                shortenAddress(account)
            );
        } else {
            setRendered("");
        }
    }, [account, ens, setRendered]);

    useEffect(() => {
        if (error) {
            console.error(
                "Error while connecting wallet:",
                error.message
            );
        }
    }, [error]);

    const etherBalance = useEtherBalance(
        account
    );

    

    return (
        <>
            <div>
                {account ? (
                    <div style={{display: "flex"}}>
                    {etherBalance && <div>
                        <Image className={"small"} src={ethLogo} alt="eth-logo" /> {
                        Number(formatEther(etherBalance)).toFixed(2)} ETH</div>
                    }
                    {confettiBalance && <div>
                        <Image className={"small"} src={cftiLogo} alt="cfti-logo" /> {
                        Number(formatEther(confettiBalance)).toFixed(2)} CFTI</div>
                    }
                    </div>
                ) : (
                    <div style={{display: "flex"}}>
                        <div>
                            <Image className={"small"} src={ethLogo} alt="eth-logo" />
                            {""} 0.00 ETH
                        </div>
                        <div>
                            <Image className={"small"} src={cftiLogo} alt="cfti-logo" />
                            {""} 0.00 CFTI
                        </div>
                    </div>
                )}
            </div>
            {
                rendered !== "" && (
                    <div style={{display: "flex"}}>
                        <div
                            className={"click-cursor"}
                            style={{marginLeft: "20px", marginRight: "20px"}}
                        >
                            <img width="40" src={perseIcon} alt="walletIcon" />
                            {" "}{rendered}{" "}
                        </div>
                        <div
                            className={"click-cursor"}
                            style={{marginRight: "20px"}}
                            onClick={openModal}
                        >
                            <img alt={"rules"} width="30" src={rulesIcon} /> Rules
                        </div>
                    </div>
                )
            }
            {
                rendered === "" && (
                    <Button
                        primary
                        onClick={() => {
                            if (!account) {
                                activateBrowserWallet();
                            }
                        }}
                    >
                        Connect Wallet
                    </Button>
                )
            }
            <Modal
                className="modal"
                overlayClassName="overlay"
                isOpen={modalIsOpen}
                ariaHideApp={false}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Rules"
            >
                <div className={"rules-modal"}>
                    <p>
                        <h2>
                            Insurance Rules and Details
                        </h2>
                    </p>
                    <br />
                    <ul>
                        <div>1) Enhance your fighter in the main game</div>
                        <div>2) Purchase insurance before the current seed ends</div>
                        <div>3) Reveal on this site before the next seed ends</div>
                    </ul>
                    <br />

                    <ul>
                        - If enhancement fails, the full cost of the enhancement is refunded in the reveal transaction
                    </ul>
                    <br />
                        <Button className={"rules-button"} onClick={() => switchDetails()}
                            primary= {ruleMode === HERO
                                ? true
                                : false
                        }>
                            Hero prices
                        </Button>
                        <Button className={"rules-button"} onClick={() => switchDetails()}
                            primary={ruleMode === FIGHTER
                                ? true
                                : false
                        }>
                            Fighter prices
                        </Button>
                    <br />
                    <br />
                    <div style={{height: "500px"}}>
                        {
                            ruleMode === FIGHTER
                                ? tableFighters()
                                : tableHeros()
                        }
                    </div>
                </div>
            </Modal>
        </>
    );
}

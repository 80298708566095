import { createSelector } from "@reduxjs/toolkit";

const selectDomain = state => state.events;

export const selectEvents = createSelector(
    [selectDomain],
    state => state
);

export const selectPurchaseEvents = createSelector(
    [selectDomain],
    state => state.purchaseEvents
);

export const selectClaimingEvents = createSelector(
    [selectDomain],
    state => state.claimingEvents
);

import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
import Insurance from '../ethers/abis/Insurance.json';

const InsuranceInterface = new Interface(
    Insurance
);

export const useNextBatch = (
    insuranceAddress
) => {
    const haveParams = insuranceAddress;
    const [nextBatch, timeUntil, timeStamp] = useContractCall(haveParams &&
        {
            abi: InsuranceInterface,
            address: insuranceAddress,
            method: "secondsUntilNextBatch"
        }
    ) ?? [];

    return [nextBatch, timeUntil, timeStamp];
}


export default useNextBatch;

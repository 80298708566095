import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
import Insurance from '../ethers/abis/Insurance.json';

const InsuranceInterface = new Interface(
    Insurance
);

export const useGetCostsHero = (
    insuranceAddress,
    tokenID
) => {
    const haveParams = insuranceAddress && tokenID;
    const [enhanceCost, insuranceCost] = useContractCall(haveParams &&
        {
            abi: InsuranceInterface,
            address: insuranceAddress,
            method: "getCostsHero",
            args: [tokenID],
        }
    ) ?? [];

    return {
        enhanceCostHero: enhanceCost,
        insuranceCostHero: insuranceCost
    }
}

export default useGetCostsHero;
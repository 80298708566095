import "./index.css";

import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache
} from "@apollo/client";

import {
    DAppProvider,
    Mainnet,
    ChainId
} from "@usedapp/core";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./state";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

// Change this to your own Infura project id: https://infura.io/register
// const INFURA_PROJECT_ID = "b17509e0e2ce45f48a44289ff1aa3c73";
const ALCHEMY_URL = "https://eth-mainnet.alchemyapi.io/v2/cxcrgclZVo_rbZ0y0jW546x29YjIGJnL";

const config = {
    readOnlyChainId: ChainId.Mainnet,
    readOnlyUrls: {
        // [Mainnet.chainId]: "https://mainnet.infura.io/v3/" + INFURA_PROJECT_ID,
        [Mainnet.chainId]: ALCHEMY_URL,
    },
}

// You should replace this url with your own and put it into a .env file
// See all subgraphs: https://thegraph.com/explorer/
const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: "https://api.thegraph.com/subgraphs/name/paulrberg/create-eth-app",
});

ReactDOM.render(
    <React.StrictMode>
        <DAppProvider config={config}>
            <ApolloProvider client={client}>
                <BrowserRouter>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </BrowserRouter>
            </ApolloProvider>
        </DAppProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);

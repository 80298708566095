import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
// import Game from '../ethers/abis/Game.json';
import Insurance from '../ethers/abis/Insurance.json';

const InsuranceInterface = new Interface(
    Insurance
);

export const usePotentialFighters = (
    insuranceAddress,
    playerAddress
) => {
    const haveParams = insuranceAddress && playerAddress;
    const [potentialFighters] = useContractCall(haveParams &&
        {
            abi: InsuranceInterface,
            address: insuranceAddress,
            method: "potentialRegisterIDsUserFighter",
            args: [playerAddress],
        }
    ) ?? [];

    return potentialFighters;
}

export default usePotentialFighters;

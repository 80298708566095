import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
import Insurance from '../ethers/abis/Insurance.json';

const InsuranceInterface = new Interface(
    Insurance
);

export const useGetBatch = (
    insuranceAddress
) => {
    const haveParams = insuranceAddress;
    const [batchNumber] = useContractCall(haveParams &&
        {
            abi: InsuranceInterface,
            address: insuranceAddress,
            method: "getBatch"
        }
    ) ?? [];

    return batchNumber;
}

export default useGetBatch;

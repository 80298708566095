import Insurance from "../ethers/abis/Insurance.json";
import { Interface } from "ethers/lib/utils";
import { formatEther } from "@ethersproject/units";

import {
    FIGHTER,
    MAX_HERO_LEVEL,
    MAX_FIGHTER_LEVEL,
    HERO,
    GENESIS_LIMIT
} from "../config";

export const insuranceInterface = new Interface(Insurance);

export const getPurchaseData = (event) => ({
    user: event.args.user,
    isFighter: event.args.isFighter,
    tokenID: event.args.tokenID.toNumber(),
    batch: event.args.batch.toNumber(),
    blockNumber: event.blockNumber,
});

export const getClaimingData = (event) => ({
    user: event.args.user,
    isFighter: event.args.isFighter,
    tokenID: event.args.tokenID.toNumber(),
    batch: event.args.batch.toNumber(),
    blockNumber: event.blockNumber,
    nftCompensation: event.args.nftCompensation,
    tokensClaimed: event.args.tokensClaimed?.toString(),
    transactionHash: event.transactionHash,
});

export const parseLog = (log, contractInterface) => ({
    blockNumber: log.blockNumber,
    transactionHash: log.transactionHash,
    ...contractInterface.parseLog(log),
});

export const parseInsuranceLog = (log) => parseLog(
    log,
    insuranceInterface
);

export const revealFailed = (tokenID, currentBatch, claimingEvents) => {
    const res = claimingEvents.filter((event) => {
        return event.tokenID === parseInt(tokenID) && event.batch === currentBatch;
    });
    return res.length > 0 && Number(formatEther(res[0].tokensClaimed)) > 0;
};

export const canReveal = (
    tokenID,
    currentBatch,
    tokenType,
    purchaseEvents = []
) => {
    const isFighter = tokenType === FIGHTER;
    const res = purchaseEvents.some((event) => {
        return (
            event.tokenID === parseInt(tokenID) &&
            event.batch === currentBatch - 1 &&
            event.isFighter === isFighter
        );
    });

    return res;
};

export const alreadyReveal = (
    tokenID,
    currentBatch,
    tokenType,
    claimingEvents = []
) => {
    const isFighter = tokenType === FIGHTER;
    const res = claimingEvents.some((event) => {
        return (
            event.tokenID === parseInt(tokenID) &&
            event.batch === currentBatch &&
            event.isFighter === isFighter
        );
    });
    return res;
};

export const awaitingReveal = (
    tokenID,
    currentBatch,
    tokenType,
    purchaseEvents
) => {
    const isFighter = tokenType === FIGHTER;
    const res = purchaseEvents.some((event) => {
        return (
            event.tokenID === parseInt(tokenID) &&
            event.batch === currentBatch &&
            event.isFighter === isFighter
        );
    });
    return res;
};

export const checkPurchaseHero = (
    purchaseHero,
    gameHero
) => {
    return purchaseHero && purchaseHero === gameHero.toString();
};

export const checkPurchaseFighter = (
    tokenID,
    purchaseTokens,
) => {
    const tokenNumber = parseInt(tokenID);
    return purchaseTokens && purchaseTokens.includes(
        tokenNumber
    );
};

export const heroButtonStyle = (purchaseHero, gameHero, level) => {
    if (level > MAX_HERO_LEVEL) {
        return false
    }

    return checkPurchaseHero(
        purchaseHero,
        gameHero
    );
}

export const heroButtonText = (purchaseHero, gameHero, level) => {
    if (level > MAX_HERO_LEVEL) {
        return "Level too high";
    }

    return checkPurchaseHero(purchaseHero, gameHero)
        ? "Buy Insurance"
        : "Enhance First";
}

export const statusClassLevelCheckPurchase = (level, type) => {
    const maxLevel = type === HERO
        ? MAX_HERO_LEVEL
        : MAX_FIGHTER_LEVEL;

    return level > maxLevel
        ? "level-too-high"
        : "can-purchase";
}

export const statusClassLevelCheckEnhance = (level, type) => {
    const maxLevel = type === HERO
        ? MAX_HERO_LEVEL
        : MAX_FIGHTER_LEVEL;

    return level > maxLevel
        ? "level-too-high"
        : "must-enhance";
}

export const isHeroGenesis = (hero) => {
    return hero < GENESIS_LIMIT;
}

export const isEqualUrl = (urlA, urlB) => {
    if (!urlA || !urlB) {
        return false
    }
    const urlObjA = new URL(urlA);
    const urlObjB = new URL(urlB);

    return (
        urlObjA.hostname === urlObjB.hostname
        && urlObjA.pathname === urlObjB.pathname
    )
}
import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
import Game from '../ethers/abis/Game.json';

const GameInterface = new Interface(Game);

export const useGameTokens = (
    gameAddress,
    playerAddress
) => {
    const haveParams = gameAddress && playerAddress;
    const [userFighters] = useContractCall(haveParams &&
        {
            abi: GameInterface,
            address: gameAddress,
            method: "getUserFighters",
            args: [playerAddress],
        }
    ) ?? [];

    return userFighters;
}

export default useGameTokens;

// import Web3 from 'web3';
// import axios from 'axios';

import cryptoPunksABI from "./abis/cryptoPunksABI.json";
// import indexedTransferABI from "./abis/indexedTransferABI.json";
import enumerableABI from "./abis/enumerableABI.json";

import {
    // testnetEnumerable,
    testnetIndexedTransfer,
    testnetPeculiarContracts
} from "./testnet";

/*export const enumerableContracts = [
    {
        name: "Bored Apes",
        address: "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
    },
    {
        name: "Mutant Apes",
        address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
    },
    {
        name: "Cool Cats",
        address: "0x1A92f7381B9F03921564a437210bB9396471050C",
    },
    {
        name: "Doodles",
        address: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
    },
    {
        name: "Bored Kennels",
        address: "0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623",
    },
    {
        name: "Azuki ", // @TODO: better way to define singular/plurarl
        address: "0xED5AF388653567Af2F388E6224dC7C4b3241C544",
    },
    {
        name: "DeadFellaz",
        address: "0x2acAb3DEa77832C09420663b0E1cB386031bA17B",
    },
    {
        name: "mfers",
        address: "0x79FCDEF22feeD20eDDacbB2587640e45491b757f",
    },
];
*/

export const indexedTransferContracts = [
    {
        name: "Raid Hero ",
        address: "0x966731dFD9b9925DD105FF465687F5aA8f54Ee9f",
    },
    {
        name: "Raid Fighters",
        address: "0x87E738a3d5E5345d6212D8982205A564289e6324",
    },
];

export const peculiarContracts = [
    {
        name: "CryptoPunks",
        address: "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
    },
    // {
    //   name: 'CryptoKitties',
    //   address: '0x06012c8cf97BEaD5deAe237070F9587f8E7A266d',
    // },
];

export const allNftContracts = [
    ...peculiarContracts,
    // ...enumerableContracts,
    ...indexedTransferContracts,
];

export const nftAddressToNameMapping = allNftContracts.reduce(
    (obj, acc) => ({ ...obj, [acc.address]: acc.name }),
    {}
);

export const nftNameToAddressMapping = allNftContracts.reduce(
    (obj, acc) => ({ ...obj, [acc.name]: acc.address }),
    {}
);

// const enumerableAddresses = enumerableContracts.map((c) => c.address);
// const indexedTransferAddresses = indexedTransferContracts.map((c) => c.address);
const peculiarAddresses = peculiarContracts.map((c) => c.address);

export const getAbi = (address) => {
    // if (indexedTransferAddresses.includes(address)) return indexedTransferABI;
    if (peculiarAddresses.includes(address)) return cryptoPunksABI;
    return enumerableABI;
    // if (enumerableAddresses.includes(address)) return enumerableABI;
};

export const isPunk = (address) => {
    const punkAddresses = [
        "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
        "0x2f1dC6E3f732E2333A7073bc65335B90f07fE8b0",
    ];
    return punkAddresses.includes(
        address
    );
};

export const getTokenCollections = (chainId) => {
    if (chainId === 3) {
        return {
            // enumerable: testnetEnumerable,
            indexedTransfer: testnetIndexedTransfer,
            peculiar: testnetPeculiarContracts,
        };
    } else {
        return {
            // enumerable: enumerableContracts,
            indexedTransfer: indexedTransferContracts,
            peculiar: peculiarContracts,
        };
    }
};

const allTestNetContracts = [
    // ...testnetEnumerable,
    ...testnetIndexedTransfer,
    ...testnetPeculiarContracts,
];

export const getMainNetAddress = (address) => {
    const testnetVersion = allTestNetContracts.filter((c) => c.address === address)[0];
    if (!testnetVersion) return address;
    const { name } = testnetVersion;
    const mainNetVersion = allNftContracts.filter((c) => c.name === name)[0];
    if (!mainNetVersion) return address;
    return mainNetVersion.address;
};

import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
import Insurance from '../ethers/abis/Insurance.json';

const InsuranceInterface = new Interface(
    Insurance
);

export const useGetStatsFighter = (
    insuranceAddress,
    tokenID
) => {
    const haveParams = insuranceAddress && tokenID;
    const [damage, level] = useContractCall(haveParams &&
        {
            abi: InsuranceInterface,
            address: insuranceAddress,
            method: "getStatsFighter",
            args: [tokenID],
        }
    ) ?? [];

    return {
        fighterDamage: damage,
        fighterLevel: level
    }
}

export default useGetStatsFighter;
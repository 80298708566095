import styled from "styled-components";

export const Body = styled.div`
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    justify-content: center;
    margin-top: 40px;
`;

export const Button = styled.button`
    background-color: white;
    border: none;
    border-radius: 8px;
    color: #282c34;
    cursor: pointer;
    font-size: 16px;
    margin: 0px 20px;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;

    &:hover {
        color: #dc58b4;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh);
`;

export const Header = styled.header`
    align-items: center;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 70px;
    background: #0f071d;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 1;
`;

export const Image = styled.img`
    &.main {
        max-height: 400px;
        margin-bottom: -80px;
        pointer-events: none;
    }
`;

export const Link = styled.a.attrs({
    target: "_blank",
    rel: "noopener noreferrer",
})`
    color: #61dafb;
    margin-top: 8px;
`;

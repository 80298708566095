import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
import Insurance from '../ethers/abis/Insurance.json';

const InsuranceInterface = new Interface(
    Insurance
);

export const useGetCosts = (
    insuranceAddress,
    tokenID
) => {
    const haveParams = insuranceAddress && tokenID;
    const [enhanceCost, insuranceCost] = useContractCall(haveParams &&
        {
            abi: InsuranceInterface,
            address: insuranceAddress,
            method: "getCostsFighter",
            args: [tokenID],
        }
    ) ?? [];

    return {
        enhanceCostFighter: enhanceCost,
        insuranceCostFighter: insuranceCost
    }
}

export default useGetCosts;

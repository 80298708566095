import indexedTransferABI from "./abis/indexedTransferABI.json";

export const testnetIndexedTransfer = [
    {
        name: "WonderPals",
        address: "0xb32a844E0C24BAB10dCa05DBef03D3b794BCc149",
    },
];

export const testnetPeculiarContracts = [
    {
        name: "CryptoPunks",
        address: "0x2f1dC6E3f732E2333A7073bc65335B90f07fE8b0",
    },
];

// export const testnetCryptoPunksFetch = (account) =>
// cryptoPunksStyleFetch(account, "0x2f1dC6E3f732E2333A7073bc65335B90f07fE8b0");

export const allNftContracts = [
    // ...testnetPeculiarContracts,
    // ...testnetEnumerable,
    ...testnetIndexedTransfer,
];

export const testnetNftAddressToNameMapping = allNftContracts.reduce(
    (obj, acc) => ({ ...obj, [acc.address]: acc.name }),
    {}
);
export const testnetNftNameToAddressMapping = allNftContracts.reduce(
    (obj, acc) => ({ ...obj, [acc.name]: acc.address }),
    {}
);

const indexedTransferAddresses = testnetIndexedTransfer.map((c) => c.address);
// const peculiarAddresses = testnetPeculiarContracts.map((c) => c.address);

export const testnetGetAbi = (address) => {
    if (indexedTransferAddresses.includes(address)) return indexedTransferABI;
    // if (peculiarAddresses.includes(address)) return cryptoPunksABI;
    // return enumerableABI;
};

import React from "react";
import { useSelector } from "react-redux";
import config, { FIGHTER, MAX_FIGHTER_LEVEL, OPENSEA_DEFAULT_TOKEN_IMG } from "../config";
import { Button } from "nes-react";

import useGetStatsFighter from "../hooks/useGetStatsFighter";
import defaultAvatar from '../backgrounds/default.png';

import {
    selectEvents,
    selectBatch
} from "../state";

import {
    canReveal,
    alreadyReveal,
    checkPurchaseFighter,
    awaitingReveal,
    isEqualUrl
} from "./helpers";

export const FightersData = ({
    index,
    tokenID,
    purchaseTokens,
    openModal,
    isIdleToken = false,
    tokenImage
}) => {

    const events = useSelector(
        selectEvents
    );

    const batch = useSelector(
        selectBatch
    );

    const { purchaseEvents, claimingEvents } = events;
    const { currentBatch } = batch;

    const stats = useGetStatsFighter(
        config.insuranceAddress,
        tokenID
    );

    //@TODO: move to helper? MAKE GENERIC!
    const buttonText = (tokenID, level) => {

        if (level > MAX_FIGHTER_LEVEL) {
            return "Level too high";
        }

        return checkPurchaseFighter(tokenID, purchaseTokens)
            ? "Buy Insurance"
            : "Enhance First";
    }

    //@TODO: move to helper? MAKE GENERIC!
    const buttonStyle = (tokenID, level) => {

        if (level > MAX_FIGHTER_LEVEL) {
            return false
        }

        return checkPurchaseFighter(tokenID, purchaseTokens)
            ? true
            : false;
    }

    const handleClick = () => {
        if (isIdleToken) {
            return;
        }
        openModal(
            tokenID,
            index,
            stats.fighterLevel
        )
    }

    let avatarStyle;
    let avatarImage = isIdleToken
        ? defaultAvatar
        : `https://cdn.raid.party/fighter/transparent/${tokenID}.png`;

    if (tokenImage
        && isEqualUrl(
            tokenImage,
            OPENSEA_DEFAULT_TOKEN_IMG
        ) === false
    ) {
        avatarImage = tokenImage;
    }

    if (tokenImage
        && isEqualUrl(
            tokenImage,
            OPENSEA_DEFAULT_TOKEN_IMG
        ) === true
    ) {
        avatarStyle = {visibility: 'hidden'}
    }

    return (
        <div
            className={!isIdleToken ? "single-item" : ""}
            style={{ marginTop: "50px" }}
            key={index}
            onClick={handleClick}
        >
            <div>
                Fighter: {tokenID}
            </div>
            {stats && stats.fighterLevel && (
                <div>
                    <div className={"level-bubble-left"}>
                        LVL: {stats.fighterLevel.toString()}
                    </div>
                    <div className={"level-bubble-right"}>
                        DMG: {stats.fighterDamage.toString()}
                    </div>
                </div>
            )}
            <div className={isIdleToken === false
                ? "fighter-holder"
                : "fighter-holder-static"
            }
            >
                <div className={"f-img-holder"}
                >
                    <img
                        style={avatarStyle}
                        alt={``}
                        src={avatarImage}
                    />
                </div>
            </div>
            {!isIdleToken && <>
                {canReveal(tokenID, currentBatch, FIGHTER, purchaseEvents) === true ? (
                    <div>
                        {alreadyReveal(tokenID, currentBatch, FIGHTER, claimingEvents) === false ? (
                            <Button success>
                                Safe Reveal
                            </Button>
                        ) : (
                            <Button error>
                                Completed
                            </Button>
                        )}
                    </div>
                ) : (
                    <div>
                        {awaitingReveal(tokenID, currentBatch, FIGHTER, purchaseEvents) === true ? (
                            <Button warning>
                                Await Seed
                            </Button>
                        ) : (
                            <Button
                                primary={buttonStyle(tokenID, stats.fighterLevel && stats.fighterLevel.toNumber())}
                            >
                                {
                                    buttonText(
                                        tokenID,
                                        stats.fighterLevel
                                    )
                                }
                            </Button>
                        )}
                    </div>
                )}
            </>}
            {isIdleToken && <div>
                <div>
                    Equip First
                </div>
            </div>}
        </div>
    );
}

export default FightersData;

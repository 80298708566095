import React, { useState } from "react";
import {useSelector} from "react-redux";
import config, { APPROVE_MAX_VALUE, FIGHTER, HERO, COST_WITH_HERO } from "../config";
import useAllowance from "../hooks/useAllowance";
import Insurance from '../ethers/abis/Insurance.json';
import ERC20 from '../ethers/abis/ERC20.json';
import { selectEvents, selectBatch } from "../state"
import { formatEther } from "@ethersproject/units";
import { Interface } from "ethers/lib/utils";
import { Contract } from "ethers";
import cftiLogo from "../images/cfti-pixel.png";
// import cn from "classnames";
import toast from 'react-hot-toast';

import {
    useEthers,
    // shortenIfTransactionHash
} from "@usedapp/core";

import {
    Balloon,
    Button
} from "nes-react";

import {
    // Header,
    Image,
    Link
} from "../components";

const insuranceInterface = new Interface(
    Insurance
);

// @TODO: can be moved to helper
const notifyError = (error) => {

    const errorMessage = error && error.error && error.error.message
        ? error.error.message
        : 'Metamask Error';

    toast.error(
        errorMessage
    );
}

export const ModalContent = ({
    canPurchase,
    closeModal,
    selectedToken,
    tokenPosition,
    serviceCosts,
    statusClass,
    refetch,
    tokenType,
    tokenLevel
    // setToggleReset,
}) => {
    const events = useSelector(selectEvents);
    const batch = useSelector(selectBatch);
    const { purchaseEvents, claimingEvents } = events;
    const { currentBatch } = batch;

    //@TODO: move to helper (duplicate)
    const canReveal = (tokenID, currentBatch) => {
        const res = purchaseEvents.filter((event) => {
            return event.tokenID === parseInt(tokenID) && event.batch === currentBatch - 1;
        });
        return res.length > 0;
    }

    //@TODO: move to helper?
    const getRevealTx = (tokenID, currentBatch) => {
        const res = claimingEvents.filter((event) => {
            return event.tokenID === parseInt(tokenID) && event.batch === currentBatch;
        });
        return res;
    }

    const getEnhanceCosts = (type) => {

        if (type === FIGHTER) {
            return serviceCosts.enhanceCostFighter;
        };

        if (type === HERO) {
            return serviceCosts.enhanceCostHero;
        }
    }

    const getInsuranceCosts = (type) => {

        if (type === FIGHTER) {
            return serviceCosts.insuranceCostFighter;
        };

        if (type === HERO) {
            return serviceCosts.insuranceCostHero;
        }
    }

    const getFighterOrHero = (type, level) => {
        if (type === HERO) {
            return level >= COST_WITH_HERO
                ? '+ Hero'
                : '';
        }

        if (type === FIGHTER) {
            return '+ Fighter';
        }
    }

    //@TODO: move to helper (duplicate)
    const revealPossible = canReveal(
        selectedToken,
        currentBatch
    );

    const closeReset = () => {
        /*setToggleReset(
            prevArray => [
                ...prevArray,
                parseInt(selectedToken)
            ]
        );*/
        closeModal();
    }

    const tokenTypeClass = tokenType && tokenType.toLowerCase();

    if (!getEnhanceCosts(tokenType)) {
        return (
            <div className={"loading"}>
                {!getEnhanceCosts(tokenType) && (
                    <div>
                        <br />
                        <br />
                        <div className={"load-holder"}>
                            <div style={{marginTop: "-10px"}} className={"pumpkin"} />
                        </div>
                        <div style={{textAlign: "center", marginTop: "150px", width: "300px"}}>
                            Fetching Status
                        </div>
                    </div>
                )}
            </div>
        );
    }

    if (statusClass === "must-enhance") {
        return (
            <div className={"where-to-go"}>
                <h3>
                    {tokenType} must be Enhanced before Reveal
                </h3>
                Go to:{" "}
                <Link href="https://play.raid.party/enhance">
                    https://play.raid.party/enhance
                </Link>
                <br />
            </div>
        );
    }

    if (statusClass === "level-too-high") {
        return (
            <div className={"lvl-too-high"}>
                <h3>
                    <div className={"where-to-go"}>
                        {tokenType} is too strong for our magic. We can't help you!
                    </div>
                </h3>
            </div>
        );
    }

    if (statusClass === "can-purchase") {
        return (
        <div className={`castle-${tokenTypeClass}`}>
            <div className={"purchase-header"} style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                    <h2>
                        Protect your {tokenType}!
                    </h2>
                    <div className={`bubble-chat-${tokenTypeClass}`}>
                        <Balloon fromLeft style={{color: "black"}}>
                        {serviceCosts && getInsuranceCosts(tokenType) && (
                            <div>The price is:{" "}<Image src={cftiLogo} alt="cfti-logo" /> {
                                Number(formatEther(getInsuranceCosts(tokenType))).toFixed(2)
                            } CFTI
                            </div>
                        )}
                        </Balloon>
                    </div>
                </div>
                <div>
                    <button onClick={closeModal}>
                        Go Back
                    </button>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            {tokenType === FIGHTER && (
                <div className={"chest"}>
                </div>
            )}
            {tokenType && (
                <div className={"selected-token"}>
                    <img alt={"fighter"} src={`https://cdn.raid.party/${tokenType.toLowerCase()}/transparent/${selectedToken}.png`} />
                </div>
            )}
            <br />
            <div style={{visibility: "hidden"}}>
                {serviceCosts && getEnhanceCosts(tokenType) && (
                    <div>
                        <div>Payout Conditions: </div>
                        <div>In case upgrade fails you will get <Image src={cftiLogo} alt="cfti-logo" />  {
                            Number(formatEther(getEnhanceCosts(tokenType))).toFixed(2)
                        } CFTI {getFighterOrHero(tokenType, tokenLevel )}
                        </div>
                    </div>
                )}
            </div>
            <div className={"purchase-button"}>
                {serviceCosts && getEnhanceCosts(tokenType) && (
                    <div>
                        <div style={{paddingBottom: "15px"}}>If enhance fails you will get <Image src={cftiLogo} alt="cfti-logo" />  {
                            Number(formatEther(getEnhanceCosts(tokenType))).toFixed(2)
                        } CFTI {getFighterOrHero(tokenType, tokenLevel)}
                        </div>
                    </div>
                )}
                <ActionButton
                    tokenPosition={tokenPosition}
                    selectedToken={selectedToken}
                    serviceCosts={serviceCosts}
                    canPurchase={canPurchase}
                    tokenType={tokenType}
                    refetch={refetch}
                />
                {/*serviceCosts && serviceCosts.enhanceCostFighter && (
                    <div>
                        <div>Payout Conditions: </div>
                        <div>In case upgrade fails you will get <Image src={cftiLogo} alt="cfti-logo" />  {
                            Number(formatEther(serviceCosts.enhanceCostFighter)).toFixed(2)
                        } CFTI + Fighter
                        </div>
                    </div>
                )*/}
            </div>
        </div>
    )};

    if (statusClass === "await-reveal" && revealPossible === false) {
        return (
            <div>
                <h2 style={{padding: "20px"}}>
                {tokenType} has Insurance!
                </h2>
                <div className={"second-chat"}>
                    <Balloon fromLeft style={{color: "black", marginLeft: "100px"}}>
                        Hey, I'm invincible!
                    </Balloon>
                </div>
                {tokenType && (
                    <div className={"selected-token-inside"}>
                        <img alt={"fighter"} src={`https://cdn.raid.party/${tokenType.toLowerCase()}/transparent/${selectedToken}.png`} />
                    </div>
                )}
                <div className={"wait-button"}>
                    <div style={{textAlign: "center", paddingBottom: "10px"}}>
                        Insurance available for reveal only during next Seed
                    </div>
                    <Button warning onClick={closeModal} style={{width: "100%"}}>
                        Awaiting Seed
                    </Button>
                </div>
            </div>
        )
    }

    if (statusClass === "await-reveal" && revealPossible === true) {
        return (
            <RevealButton
                tokenPosition={tokenPosition}
                selectedToken={selectedToken}
                serviceCosts={serviceCosts}
                canPurchase={canPurchase}
                refetch={refetch}
                tokenType={tokenType}
            />
        )
    }

    if (statusClass === "reveal-success") {
        return (
            <div className={"where-to-go"}>
                <h4>
                    Congrats! {tokenType} has been enhanced successfully.<br />
                    Cool down until next seed to repeat!
                    {/*<Link href="https://play.raid.party/enhance">
                        Show Transaction
                    </Link>*/}
                </h4>
                <div className={"bottom"}>
                    {/*<Link href="https://play.raid.party/enhance">
                        https://play.raid.party/enhance
                    </Link>*/}
                    <Button error onClick={closeReset} style={{width: "100%"}}>
                        Quest Completed!
                    </Button>
                </div>
            </div>
        );
    }

    if (statusClass === "reveal-failed") {

        const revealTx = getRevealTx(
            selectedToken,
            currentBatch
        );

        return (
            <div className={"where-to-go"}>
                <h4>
                    Oh no! Enhancement failed, but we've compensated your loss with Insurance!{" "}
                </h4>
                {revealTx && revealTx[0] && (
                    <Link href={`https://etherscan.io/tx/${revealTx[0].transactionHash}`}>
                        Show Transaction
                    </Link>
                )}
                <div className={"bottom"}>
                    <Button error onClick={closeReset} style={{width: "100%"}}>
                        Let's try again in next round!
                    </Button>
                </div>
            </div>
        );
    }
}

export const RevealButton = ({
    canPurchase,
    tokenPosition,
    selectedToken,
    serviceCosts,
    tokenType,
    refetch,
}) => {

    const {
        library,
        account
        // chainId
    } = useEthers();

    const DEFAULT_TEXT = 'Proceed with Safe Reveal';
    const CONFIRM_TEXT = 'Confirm using MetaMask';

    const ONGOING_TEXT = 'Revealing...';
    const DONE_TEXT = 'Safe Reveal Completed';

    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    // const [transactionHash, setTransactionHash] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const signer = library.getSigner(
        account
    );

    const insuranceContract = new Contract(
        config.insuranceAddress,
        insuranceInterface,
        signer
    );

    const isDisabled = buttonDisabled
        || !serviceCosts;

    const caimInsurance = async () => {

        try {

            setButtonDisabled(true);
            setButtonText(CONFIRM_TEXT);

            const claimTransaction = tokenType === HERO
                ? await insuranceContract.insuranceClaimHero(
                    selectedToken
                )
                : await insuranceContract.insuranceClaimFighter(
                    selectedToken,
                    tokenPosition
                );

            setIsLoading(true);
            setButtonText(ONGOING_TEXT);
            // setTransactionHash(claimTransaction.hash);

            await claimTransaction.wait();

            refetch();
            setIsLoading(false);
            setButtonText(DONE_TEXT);

        } catch (err) {
            setIsLoading(false);
            setButtonDisabled(false);
            setButtonText(DEFAULT_TEXT);
            notifyError(err);
        }
    }

    return (
        <div>
            <h2 style={{padding: "20px"}}>
                {tokenType} has Insurance!
            </h2>
            <div className={"second-chat"}>
                <Balloon fromLeft style={{color: "black", marginLeft: "100px"}}>
                    It's now or never!
                </Balloon>
            </div>
            {tokenType && (
                <div className={"selected-token-inside"}>
                    <img alt={"fighter"} src={`https://cdn.raid.party/${tokenType.toLowerCase()}/transparent/${selectedToken}.png`} />
                </div>
            )}
            <div className={"safe-reveal"}>
                <button
                    style={{width: "100%", justifyContent: "center"}}
                    disabled={isDisabled}
                    onClick={() => {
                    caimInsurance()
                }}>
                    <h3 className={"btn-act"}>
                        {isLoading && (
                            <div className={"loader"} />
                        )}
                        {buttonText}
                    </h3>
                </button>
            </div>
        </div>
    );
};

//@TODO: possible split in separate file
export const ActionButton = ({
    canPurchase,
    tokenPosition,
    selectedToken,
    serviceCosts,
    tokenType,
    refetch,
}) => {

    const {
        library,
        account
        // chainId
    } = useEthers();

    const DEFAULT_TEXT = 'Unlock and Buy Insurance';
    const CONFIRM_TEXT = 'Confirm using MetaMask';

    const ONGOING_TEXT = 'Approving...';
    const SENDING_TEXT = 'Buying...';
    const DONE_TEXT = 'Insurance Purchased!';

    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    // const [transactionHash, setTransactionHash] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const signer = library.getSigner(
        account
    );

    const insuranceContract = new Contract(
        config.insuranceAddress,
        insuranceInterface,
        signer
    );

    const tokenContract = new Contract(
        config.confettiAddress,
        ERC20,
        signer
    );

    const allowance = useAllowance(
        account,
        config.confettiAddress,
        config.insuranceAddress
    );

    const isDisabled = !allowance
        || buttonDisabled
        || !serviceCosts
        || !canPurchase;

    const doApproval = async () => {

        setButtonText(CONFIRM_TEXT);
        setButtonDisabled(true);

        const approvalTx = await tokenContract.approve(
            config.insuranceAddress,
            APPROVE_MAX_VALUE
        );

        setIsLoading(true);
        setButtonText(ONGOING_TEXT);

        // setTransactionHash(approvalTx.hash);

        await approvalTx.wait();
        setIsLoading(false);
    }

    const purchaseInsurance = async (tokenType) => {

        const preApproved = allowance.gte(
            tokenType === FIGHTER
                ? serviceCosts.insuranceCostFighter.toString()
                : serviceCosts.insuranceCostHero.toString()
        ); // false

        try {

            if (preApproved === false) {
                await doApproval();
            }

            setButtonDisabled(true);
            setButtonText(CONFIRM_TEXT);

            const buyTransaction = tokenType === HERO
                ? await insuranceContract.buyInsuranceHero(
                    selectedToken
                )
                : await insuranceContract.buyInsuranceFighter(
                    selectedToken,
                    tokenPosition
                );

            setIsLoading(true);
            setButtonText(SENDING_TEXT);
            // setTransactionHash(buyTransaction.hash);

            await buyTransaction.wait();

            refetch();
            setIsLoading(false);
            setButtonText(DONE_TEXT);

        } catch (err) {
            setIsLoading(false);
            setButtonDisabled(false);
            setButtonText(DEFAULT_TEXT);
            notifyError(err);
        }
    }

    return (
        <div style={{textAlign: "center"}}>
            <button
                style={{width: "100%", justifyContent: "center"}}
                disabled={isDisabled}
                onClick={() => {
                    purchaseInsurance(tokenType)
                }}
            >
                <h3 className={"btn-act"}>
                    {isLoading && (
                        <div className={"loader"} />
                    )}
                    {buttonText}
                </h3>
            </button>
        </div>
    );
};

export default ModalContent;

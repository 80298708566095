import { createSelector } from "@reduxjs/toolkit";

const selectDomain = state => state.batch;

export const selectBatch = createSelector(
    [selectDomain],
    state => state
);

export const selectCurrentBatch = createSelector(
    [selectDomain],
    state => state.currentBatch
);

import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
import Game from '../ethers/abis/Game.json';

const GameInterface = new Interface(
    Game
);

export const useGameHeroes = (
    gameAddress,
    insuranceAddress,
    playerAddress,
) => {
    const haveParams = gameAddress && playerAddress;
    const [userHero] = useContractCall(haveParams &&
    {
        abi: GameInterface,
        address: gameAddress,
        method: "getUserHero",
        args: [playerAddress],
    }
    ) ?? [];

    return userHero;
}

export default useGameHeroes;

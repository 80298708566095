import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Contract } from "@ethersproject/contracts";
import { Container } from "nes-react";
import { useLocation } from "react-router-dom";
import Countdown from 'react-countdown';
import Modal from 'react-modal';
import { Toaster } from 'react-hot-toast';

import { useSelector, useDispatch } from "react-redux";
import {
    selectPurchaseEvents,
    selectClaimingEvents,
    setPurchaseEvents,
    setClaimingEvents,
    selectCurrentBatch,
    setCurrentBatch,
} from "./state";

import {
    useEthers,
} from "@usedapp/core";

import {
    Body,
    Header,
    Image,
} from "./components";

import useTokenFetch from "./hooks/useTokenFetch";
import useGameTokens from "./hooks/useGameTokens";
import useGameHeroes from "./hooks/useGameHeroes";

import useGetCosts from "./hooks/useGetCosts";
import useGetBatch from "./hooks/useGetBatch";
import useNextBatch from "./hooks/useNextBatch";

import useRegisterToken from "./hooks/useRegisterToken";
import usePotentialFighters from "./hooks/usePotentialFighters";
import usePotentialHero from "./hooks/usePotentialHero";

import useGetCostsHero from "./hooks/useGetCostsHero";

import { ModalContent } from './components/actions';
import { WalletHeader } from './components/header';
import { FightersData } from './components/fighter';
import { HeroData } from './components/hero';

import {
    revealFailed,
    getPurchaseData,
    getClaimingData,
    parseInsuranceLog,
    insuranceInterface,
    canReveal,
    alreadyReveal,
    checkPurchaseHero,
    checkPurchaseFighter,
    statusClassLevelCheckPurchase,
    statusClassLevelCheckEnhance,
    awaitingReveal
} from "./components/helpers";

import {
    toastStyle,
    customStyles
} from './components/styles';

import logo from "./images/partyLogo.png";

import config, {
    FIGHTER,
    HERO
} from "./config";

import GET_TRANSFERS from "./graphql/subgraph";


const useParams = () => {
    return new URLSearchParams(
        useLocation().search
    );
};

const App = () => {

    const dispatch = useDispatch();

    const purchaseEvents = useSelector(selectPurchaseEvents);
    const claimingEvents = useSelector(selectClaimingEvents);
    const currentBatch = useSelector(selectCurrentBatch);

    const [selectedToken, setSelectedToken] = useState();
    const [tokenPosition, setTokenPosition] = useState();

    const [purchaseTokens, setPurchaseTokens] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);

    const [tokenType, setTokenType] = useState();
    const [tokenLevel, setTokenLevel] = useState();

    const refetch = () => {
        setFetchAgain(!fetchAgain);
    }

    const closeModal = () => {
        setIsOpen(false);
        setSelectedToken();
    }

    // refactor into one
    const openModal = (
        tokenID,
        tokenIndex,
        level,
    ) => {
        setTokenType(FIGHTER);
        setSelectedToken(tokenID);
        setTokenPosition(tokenIndex);
        setTokenLevel(level);
        setIsOpen(true);
    }

    // refactor into one
    const openModalHero = (
        heroID,
        level
    ) => {
        setTokenType(HERO);
        setSelectedToken(heroID);
        setTokenLevel(level);
        setIsOpen(true);
    }

    const params = useParams();
    const previewWallet = params.get('wallet');

    const {
        loading,
        error: subgraphQueryError,
        data
    } = useQuery(GET_TRANSFERS);

    const {
        account,
        // error,
        library,
    } = useEthers();

    const insuranceContract = new Contract(
        config.insuranceAddress,
        insuranceInterface
    );

    const lookupAccount = previewWallet || account;

    const walletTokens = useTokenFetch(
        lookupAccount
    );

    const gameFighters = useGameTokens(
        config.gameAddress,
        lookupAccount
    );

    const gameHero = useGameHeroes(
        config.gameAddress,
        config.insuranceAddress,
        lookupAccount
    );

    const potentialFighters = usePotentialFighters(
        config.insuranceAddressView,
        lookupAccount
    );

    const potentialHero = usePotentialHero(
        config.insuranceAddressView,
        lookupAccount
    );

    const [nextBatch, timeUntil] = useNextBatch(
        config.insuranceAddress
    );

    const latestBatch = useGetBatch(
        config.insuranceAddress
    );

    const serviceCosts = useGetCosts(
        config.insuranceAddress,
        selectedToken
    );

    const serviceCostsHero = useGetCostsHero(
        config.insuranceAddress,
        selectedToken
    );

    const registerBatch = useRegisterToken(
        config.insuranceAddress,
        selectedToken,
        tokenType
    );

    //@TODO: move to helper?
    console.log(registerBatch, 'registerBatch');
    console.log(currentBatch, 'currentBatch');
    const insurancePurchased = registerBatch && currentBatch &&
        registerBatch.toString() === currentBatch.toString();

    useEffect(() => {
        if (latestBatch && latestBatch.toNumber() > currentBatch) {
            dispatch(
                setCurrentBatch(
                    latestBatch.toNumber()
                )
            );
        }
    }, [dispatch, latestBatch, currentBatch]);

    useEffect(() => {

        const tokens = [];
        potentialFighters && potentialFighters.map((id) => {
            return tokens.push(id.toNumber());
        });

        setPurchaseTokens(
            tokens // [31335]
        );

    }, [potentialFighters]);

    useEffect(() => {

        if (subgraphQueryError) {
            console.error(
                "Error while querying subgraph:",
                subgraphQueryError.message
            );

            return;
        }
        if (!loading && data && data.transfers) {
            console.log({ transfers: data.transfers });
        }
    }, [loading, subgraphQueryError, data]);

    useEffect(() => {

        const fetchPurchaseLogs = async () => {

            const purchaseFilter = insuranceContract
                .filters
                .insurancePurchased();

            const purchaseLogs = await library.getLogs({
                ...purchaseFilter,
                fromBlock: config.inceptionBlock
            });

            const purchaseEvents = purchaseLogs.map(
                parseInsuranceLog
            );

            const purchaseData = purchaseEvents.map(
                getPurchaseData
            );

            dispatch(
                setPurchaseEvents(
                    purchaseData
                )
            );

            /*setPurchaseEvents(
                [{
                    tokenID: 31337,
                    batch: 1315
                },{
                    tokenID: 20606,
                    batch: 1315
                }]
            );*/
        };

        const fetchClaimingLogs = async () => {

            const claimingFilter = insuranceContract
                .filters
                .insuranceClaimed();

            const claimingLogs = await library.getLogs({
                ...claimingFilter,
                fromBlock: config.inceptionBlock
            });

            const claimEvents = claimingLogs.map(
                parseInsuranceLog
            );

            const claimData = claimEvents.map(
                getClaimingData
            );

            dispatch(
                setClaimingEvents(
                    claimData
                )
            );
        };

        if (library) fetchPurchaseLogs();
        if (library) fetchClaimingLogs();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [library, insurancePurchased, fetchAgain]);


    //@TODO: move to helper?
    const loadedType = (type) => {

        if (type === FIGHTER) {
            return serviceCosts && serviceCosts.enhanceCostFighter;
        };

        if (type === HERO) {
            return serviceCostsHero && serviceCostsHero.enhanceCostHero;
        }
    }

    //@TODO: move to helper?
    const getCosts = (type) => {

        if (type === FIGHTER) {
            return serviceCosts;
        };

        if (type === HERO) {
            return serviceCostsHero;
        }
    }

    const failed = revealFailed(
        selectedToken,
        currentBatch,
        claimingEvents
    );

    const isPurchased = () => {
        return tokenType === HERO
            ? checkPurchaseHero(potentialHero, gameHero)
            : checkPurchaseFighter(selectedToken, purchaseTokens);
    }

    // refactor
    let statusClass = isPurchased() === true || !loadedType(tokenType)
        ? statusClassLevelCheckPurchase(tokenLevel, tokenType)
        : statusClassLevelCheckEnhance(tokenLevel, tokenType);

    // refactor (same code in hero component)
    if (loadedType(tokenType) && awaitingReveal(
        selectedToken,
        currentBatch,
        tokenType,
        purchaseEvents
    ) === true) {
        statusClass = "await-reveal";
    }

    // refactor (probably have duplicate)
    if (loadedType(tokenType) && canReveal(
        selectedToken,
        currentBatch,
        tokenType,
        purchaseEvents
    ) === true) {
        statusClass = "await-reveal";
    }

    // refactor (probably have duplicate)
    if (loadedType(tokenType) && alreadyReveal(
        selectedToken,
        currentBatch,
        tokenType,
        claimingEvents
    ) === true) {

        if (failed === false) {
            statusClass = "reveal-success";
        }

        if (failed === true) {
            statusClass = "reveal-failed";
        }
    }

    const tokenTypeClass = tokenType && tokenType.toLowerCase();

    return (
        <Container>
            <Header>
                <WalletHeader />
            </Header>
            <Body>
                <div className={"logo-holder-float"}>
                    <Image className={"main"} src={logo} alt="ethereum-logo" />
                </div>
                <div className={"ribbon-holder"}>
                    <div className={"ribbon-left"}>
                    </div>
                    <div className={"ribbon-center"}>
                        <h1>Insurance</h1>
                    </div>
                    <div className={"ribbon-right"}>
                    </div>
                </div>
                {timeUntil && timeUntil.toNumber() === 0 && (
                    <div className={"blink"}>
                        Seed Revealing...
                    </div>
                )}
                {timeUntil && timeUntil.toNumber() > 0 && (
                    <div style={{ textAlign: "center" }}>
                        Time Left: <Countdown date={nextBatch.toNumber() * 1000} />
                        <br />
                        to buy Insurance before next Seed
                    </div>
                )}
                <div>
                    <br />
                </div>
                <div className={"token-list-holder"}>
                    <div className={"inner-list"}>
                        <HeroData
                            heroID={gameHero}
                            openModalHero={openModalHero}
                            purchaseHero={potentialHero}
                        />
                        {gameFighters && gameFighters.map((token, index) => {
                            return token.toString() === "0"
                                ? (<React.Fragment key={index} />)
                                : (
                                    <FightersData
                                        index={index}
                                        key={index}
                                        tokenID={token.toString()}
                                        purchaseTokens={purchaseTokens}
                                        openModal={openModal}
                                    />
                                )
                            }
                        )}
                    </div>
                    {walletTokens && walletTokens.length > 0 && (
                        <div className={"breaker"}>
                            <br />
                            <br />
                            <h2>
                                <br />
                                Idle Tokens
                                <br />
                            </h2>
                        </div>
                    )}
                    <div className={"inner-list"}>
                        {walletTokens && walletTokens.map((token, index) => (
                            <React.Fragment key={index}>
                                {token.address === config.heroAddress ?
                                    (
                                        <HeroData
                                            heroID={(token.id).toNumber()}
                                            isIdleToken
                                        />
                                    ) : (
                                        <FightersData
                                            tokenID={(token.id).toNumber()}
                                            tokenImage={token.data?.image}
                                            isIdleToken
                                        />
                                    )
                                }
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <img
                    alt={"backgrounder"}
                    style={{ width: "100%" }}
                    src="https://raid.party/images/tiles.png"
                />
                <Modal
                    className="modal"
                    overlayClassName="overlay"
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Quest"
                >
                    <Container className={`${statusClass}-${tokenTypeClass}`}>
                        <ModalContent
                            closeModal={closeModal}
                            selectedToken={selectedToken}
                            tokenPosition={tokenPosition}
                            serviceCosts={getCosts(tokenType)}
                            canPurchase={isPurchased()}
                            statusClass={statusClass}
                            refetch={refetch}
                            tokenType={tokenType}
                            tokenLevel={tokenLevel}
                        />
                    </Container>
                </Modal>
            </Body>
            <Toaster
                toastOptions={{
                    style: toastStyle,
                }}
            />
            {/*Latest Batch: {currentBatch}*/}
        </Container>
    );
}

export default App;

import cftiLogo from "../images/cfti-pixel.png";

import {
    Image,
} from "../components";


export const tableHeros = () => {
        
    return (
        <table border="1" cellpadding={"10px"} width={"100%"}>
            <tr>
                <th>
                    Level
                </th>
                <th>
                    Enhancement Cost
                </th>
                <th>
                    Chance
                </th>
                <th>
                    Insurance Cost
                </th>
            </tr>
            <tr>
                <td>
                    LVL 1
                </td>
                <td>
                    <Image src={cftiLogo} alt="cfti-logo" /> 250 CFTI
                </td>
                <td>
                    90%
                </td>
                <td>
                    <Image src={cftiLogo} alt="cfti-logo" /> 50 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 2
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 500 CFTI
                </td>
                <td>
                    85%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 125 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 3
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 750 CFTI
                </td>
                <td>
                    80%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 225 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 4
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 1000 CFTI
                </td>
                <td>
                    75%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 350 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 5
                </td>
                <td>
                    <Image src={cftiLogo} alt="cfti-logo" /> 1250 CFTI + Hero
                </td>
                <td>
                    70%
                </td>
                <td>
                    <Image src={cftiLogo} alt="cfti-logo" /> 1100 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 6
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 1500 CFTI + Hero
                </td>
                <td>
                    65%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 1350 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 7
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 1750 CFTI + Hero
                </td>
                <td>
                    60%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 1625 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 8
                </td>
                <td>
                <Image src={cftiLogo} alt="cfti-logo" /> 2000 CFTI + Hero
                </td>
                <td>
                    55%
                </td>
                <td>
                <Image src={cftiLogo} alt="cfti-logo" /> 1925 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 9
                </td>
                <td>
                <Image src={cftiLogo} alt="cfti-logo" /> 2250 CFTI + Hero
                </td>
                <td>
                    50%
                </td>
                <td>
                <Image src={cftiLogo} alt="cfti-logo" /> 2250 CFTI
                </td>
            </tr>
        </table>
    );
};

export const tableFighters = () => {
    
    return (
        <table border="1" cellpadding={"10px"} width={"100%"}>
            <tr>
                <th>
                    Level
                </th>
                <th>
                    Enhancement Cost
                </th>
                <th >
                    Chance
                </th>
                <th>
                    Insurance Cost
                </th>
            </tr>
            <tr>
                <td>
                    LVL 1
                </td>
                <td>
                    <Image src={cftiLogo} alt="cfti-logo" /> 25 CFTI + Fighter
                </td>
                <td>
                    90%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 25 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 2
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 35 CFTI + Fighter
                </td>
                <td>
                    85%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 34 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 3
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 50 CFTI  + Fighter
                </td>
                <td>
                    80%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 45 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 4
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 75 CFTI + Fighter
                </td>
                <td>
                    75%
                </td>
                <td>
                    <Image src={cftiLogo} alt="cfti-logo" /> 61 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 5
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 100 CFTI + Fighter
                </td>
                <td>
                    70%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 80 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 6
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 125 CFTI + Fighter
                </td>
                <td>
                    65%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 101 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 7
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 150 CFTI + Fighter
                </td>
                <td>
                    60%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 125 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 8
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 300 CFTI + Fighter
                </td>
                <td>
                    55%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 220 CFTI
                </td>
            </tr>
            <tr>
                <td>
                    LVL 9
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 350 CFTI + Fighter
                </td>
                <td>
                    50%
                </td>
                <td>
                   <Image src={cftiLogo} alt="cfti-logo" /> 270 CFTI
                </td>
            </tr>
        </table>
    );
};
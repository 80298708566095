import { Interface } from "ethers/lib/utils";
import { useContractCall } from "@usedapp/core";
import ERC20 from '../ethers/abis/ERC20.json';

const ERC20Interface = new Interface(ERC20);

export const useAllowance = (
    userAccount,
    tokenAddress,
    spenderAddress
) => {
    const haveParams = userAccount && tokenAddress && spenderAddress;
    const [allowance] = useContractCall(haveParams &&
        {
            abi: ERC20Interface,
            address: tokenAddress,
            method: "allowance",
            args: [userAccount, spenderAddress],
        }
    ) ?? [];

    return allowance;
}

export default useAllowance;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentBatch: 0
};

export const batchSlice = createSlice({
    name: "batch",
    initialState,
    reducers: {
        setCurrentBatch(state, action) {
            state.currentBatch = action.payload;
        }
    }
});

export const { setCurrentBatch } = batchSlice.actions;
export default batchSlice.reducer;
